import { axiosInstance, endpoints } from "./axiosHandler";

function fetchOffsiteForms(filters, cancelToken) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.offsiteForms, {
        params: filters,
        cancelToken: cancelToken.token
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function createOffsiteForm(creationDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.offsiteForms, creationDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function findOffsiteForm(uuid) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.offsiteForms + "find/", {
        params: {
          uuid: uuid
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fulfilOffsiteForm(creationDict) {
  let formData = new FormData();
  for (const [key, value] of Object.entries(creationDict)) {
    formData.append(key, value);
  }

  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.offsiteForms + "fulfil/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fulfilOffsiteFormPhoto(uuid, photo) {
  let formData = new FormData();
  formData.append("uuid", uuid);
  formData.append("photo", photo);

  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.offsiteForms + "add-photo/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { fetchOffsiteForms, createOffsiteForm, findOffsiteForm, fulfilOffsiteForm, fulfilOffsiteFormPhoto };
